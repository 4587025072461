.Heading {
  font-weight: 900;
  &_lvl2 {
    font-size: 24px;
    @include mf(sm) {
      font-size: 36px; } }
  .Heading_decorLine {
    position: relative;
    display: inline-block;
    z-index: 1;
    &:before {
      content: '';
      width: 100%;
      height: 40%;
      background-color: $main-color;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1; } }
  &_white {
    color: #FFFFFF; } }
