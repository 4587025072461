//@import "../../../node_modules/bootstrap/scss/bootstrap-grid"
@import "bourbon";
@import "vars";

@include font-face("GothamPro", "/font/GothamProLight/GothamProLight", 100, $file-formats: woff);
@include font-face("GothamPro", "/font/GothamProRegular/GothamProRegular", 400, $file-formats: woff);
@include font-face("GothamPro", "/font/GothamProMedium/GothamProMedium", 500, $file-formats: woff);
@include font-face("GothamPro", "/font/GothamProBold/GothamProBold", 700, $file-formats: woff);
@include font-face("GothamProBold", "/font/GothamProBold/GothamProBold", 700, $file-formats: woff);
@include font-face("GothamPro", "/font/GothamProBlack/GothamProBlack", 900, $file-formats: woff);


* {
  outline: none; }

a, p, h1, sup, span {
  color: inherit;
  font-weight: inherit; }

sup {
  font-size: 60%; }

b {
  font-weight: 700; }

svg {
  fill: currentColor; }

body {
  margin: 0;
  padding: 0;
  font-family: $webfont-default;
  font-size: 14px;
  font-weight: 400;
  color: #292929;
  @include mf(sl) {
    font-size: 16px; } }

.fonts-stage-1 body {
  font-family: $webfont-default;
  line-height: 1.25; }

.fonts-stage-2 body {
  font-family: $webfont-default;
  line-height: 1.25; }

.Container {
  width: 100%;
  max-width: 1150px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box; }

.ImCallback {
  background-color: #f0f0f0; }

.nowrap {
  white-space: nowrap; }

.fancybox-container {
  z-index: 9000; }

.fancybox-content {
  padding: 0; }

.SectionIndent {
  padding: 20px 0;
  @include mf(sl) {
    padding: 40px 0; } }
