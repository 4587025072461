.ImCallback {
  width: auto;
  max-width: 320px;
  padding: 20px 10px;
  @include mf(xs) {
    width: 320px;
    max-width: none;
    padding: 30px 20px 20px; }
  &-Title {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: $main-color;
    text-align: center;
    text-transform: uppercase; }
  &-Subtitle {
    margin-bottom: 20px;
    font-size: 14px;
    color: #444444;
    text-align: center; }
  &-Form {}
  &-Input {
    margin-bottom: 10px; }
  &-Btn {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    @include mf(xs) {
      padding-left: inherit;
      padding-right: inherit; } } }
