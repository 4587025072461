.MyDefmenuNav {
  padding-top: 15px;
  padding-bottom: 30px;
  background-color: #f0f0f0;
  &-Link {
    width: 100%;
    padding: 10px 10px 10px 15px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    display: block;
    &_open {}
    &.active {
      background-color: $main-color;
      color: #000; } }
  &-Item {}
  &-List {
    &_lvl2 {
      height: 0;
      overflow: hidden;
      &.active {
        height: auto;
        overflow: initial; }
      .MyDefmenuNav-Item {
        margin-bottom: 0; }
      .MyDefmenuNav-Link {
        padding: 5px 10px 5px 30px;
        font-size: 13px;
        font-weight: 400;
        color: #000;
        display: block;
        position: relative;
        &.active {
          background-color: #000;
          color: $main-color;
          font-weight: bold; } } } } }
