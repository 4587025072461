.DocumentCard {
  width: 255px;
  @include mf(lg) {
    width: auto; }
  &-PreviewWrap {
    width: 100%;
    height: 330px;
    margin-bottom: 18px;
    border: 8px solid #e5e5e5;
    border-radius: 3px;
    display: block;
    @include mf(lg) {
      height: auto; } }
  &-Preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; }
  &-Name {
    padding-top: 5px;
    padding-left: 15px;
    font-size: 16px;
    color: #292929;
    letter-spacing: 0;
    position: relative;
    &:before {
      content: '';
      width: 4px;
      height: 100%;
      background-color: $main-color;
      position: absolute;
      top: 0;
      left: 0; } } }
