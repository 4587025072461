.HeaderNav {
  display: none;
  @include mf(sl) {
    width: 100%;
    background-color: #fff;
    display: block;
    position: relative;
    z-index: 3; }
  &-Container {}
  &-Content {
    display: flex;
    justify-content: space-between; }
  &-Item {
    position: relative;
    &-Heading {
      padding: 20px 5px 16px;
      font-size: 14px;
      text-decoration: none;
      display: inline-block;
      //&:last-of-type
      //  padding-right: 0
      &:hover {
        font-weight: 700;
        cursor: pointer; }
      &:after {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden; }
      &.active {
        background-color: $main-color;
        font-weight: 700; } }
    &.HeaderNav-Item_active {
      .HeaderNav-Item-Heading {
        font-weight: 700; }
      .HeaderNav-Drop {
        display: flex; } } }
  &-Drop {
    background-color: #f0f0f0;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    &-Link {
      padding: 7px 14px 5px;
      font-size: 14px;
      text-decoration: none;
      white-space: nowrap;
      &.active,
      &:hover {
        background-color: $main-color;
        font-weight: 700; }
      &:after {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden; } } } }
