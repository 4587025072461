.Footer {
  padding: 17px 0 16px;
  background-color: #333333;
  .Container {}
  &-Body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @include mf(xs) {
      flex-direction: row; } }
  &-Politic {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0.5px;
    @include mf(xs) {
      margin-bottom: 10px; } }
  &-FijiLogo {
    width: 205px;
    img {
      max-width: 100%;
      height: auto;
      display: inline-block; } } }
