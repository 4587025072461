.Feedback {
  &-Body {
    @include mf(lg) {
      position: relative; } }
  &-Heading {
    margin-bottom: 20px;
    @include mf(sm) {
      margin-bottom: 50px; } }
  &-Content {
    @include mf(lg) {
      position: relative;
      z-index: 2; } }
  &-Slider {}
  &-Simg {
    display: none;
    @include mf(lg) {
      display: block;
      position: absolute; }
    &_cloud {
      @include mf(lg) {
        top: -90px;
        right: calc(100% + 55px); } }
    &_spark {
      right: -215px;
      bottom: 50px; } }
  &-Item {
    padding: 0 15px;
    @include mf(lg) {
      padding: 0; } }
  &-Card {
    margin: 0 auto; } }

.FeedbackSlider {
  position: relative;
  @include mf(lg) {
    display: flex;
    flex-wrap: wrap; }
  &-Slide {
    @include mf(lg) {
      width: 265px;
      margin-top: 50px;
      margin-right: 20px; }
    &:nth-child(4n) {
      margin-right: 0; }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 0; } } }
