.Btn {
  font-size: 100%;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center; }
button.Btn {
  display: block;
  text-align: center; }
