.FAQ {
  background-color: #292929;
  &-Heading {
    margin-bottom: 20px;
    @include mf(sl) {
      margin-bottom: 35px; } }
  & {} }


.FAQCard {
  &-Item {
    margin-bottom: 15px;
    @include mf(sm) {
      margin-bottom: 20px; }
    &:last-of-type {
      margin-bottom: 0; } }
  &-Title {
    padding-left: 20px;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 600;
    position: relative;
    display: inline-block;
    cursor: pointer;
    @include mf(sl) {
      font-size: 18px; }
    &:before {
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 10px solid $main-color;
      position: absolute;
      top: 4px;
      left: 0; } }
  &-Content {
    margin-top: 15px;
    margin-left: 20px;
    padding: 15px 12px 15px 20px;
    background-color: #e2e2e2;
    border-radius: 3px;
    @include mf(sm) {
      padding-bottom: 20px; } }
  p {
    margin-bottom: 15px;
    font-size: 14px;
    &:last-of-type {
      margin-bottom: 0; }
    b {
      font-size: 16px;
      display: block; } } }

.Accordion-Item_open {
  .FAQCard-Title:before {
    transform: rotate(-90deg); } }
