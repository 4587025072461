.Slider {
  position: relative;
  &-Slide {}
  &-Arrow {
    width: 46px;
    height: 46px;
    background-color: $main-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 23px);
    z-index: 3;
    &:hover {
      background-color: darken($main-color, 10%); }
    &_prev {
      left: 0; }
    &_next {
      right: 0;
      transform: rotate(180deg); }
    .Icon {
      width: 12px;
      height: 19px;
      margin-right: 5px;
      fill: #fff; } } }

.Slider_document {
  width: 285px;
  margin: 0 auto;
  @include mf(sx) {
    width: 570px; }
  @include mf(sl) {
    width: 855px; }
  @include mf(lg) {
    width: auto; }
  .Slider {
    &-Slide {}
    &-Arrow {
      top: 140px;
      &:hover {}
      &_prev {}
      &_next {} } } }
