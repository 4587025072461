.Btn {
  height: 47px;
  background-color: $main-color;
  font-size: 12px;
  font-family: 'GothamProBold';
  border-radius: 3px;
  text-transform: uppercase;
  -webkit-appearance: none;
  &:hover {
    background-color: darken($main-color, 10%); } }
