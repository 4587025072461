.Intro {
  background-color: #292929;
  &-Body {
    padding-top: 30px;
    padding-bottom: 55px;
    position: relative;
    @include mf(sm) {
      padding-top: 70px;
      padding-bottom: 125px; } }
  &-Content {
    max-width: 320px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    @include mf(sm) {
      max-width: 60%;
      margin: 0;
      padding-right: 45px; }
    @include mf(lg) {
      max-width: 57%; } }
  &-Heading {
    font-size: 30px;
    font-weight: 700;
    color: #FFFFFF;
    @include mf(sl) {
      font-size: 40px; } }
  &-Subheading {
    font-size: 24px;
    font-weight: 700;
    color: #9e9e9e;
    @include mf(sl) {
      font-size: 27px; }
    @include mf(lg) {
      font-size: 30px; } }
  &-Player {
    width: 100%;
    max-width: 320px;
    height: 176px;
    margin: 0 auto;
    position: relative;
    @include mf(sm) {
      position: absolute;
      top: 55px;
      right: 0;
      transform: translateX(100%); }
    @include mf(sl) {
      max-width: 370px;
      height: 205px; }
    @include mf(lg) {
      max-width: 470px;
      height: 270px;
      top: 0; }
    &-Video {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      z-index: 2; } }
  &-Simg {
    position: absolute;
    &_cloud_left {
      display: none;
      @include mf(lg) {
        display: block;
        top: 170px;
        right: calc(100% - 25px); } }
    &_cloud_right {
      width: 203px;
      right: -25px;
      bottom: 0;
      @include mf(lg) {
        width: auto;
        right: -300px; } } }
  &-SpecText {
    font-size: 90px;
    font-weight: 900;
    color: #FFFFFF;
    opacity: 0.02;
    line-height: 0.75;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    @include mf(sm) {
      left: auto;
      right: 0;
      transform: none; }
    @include mf(lg) {
      font-size: 250px;
      right: -400px;
      bottom: -20px; } }
  &-Form {
    max-width: 320px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    @include mf(sm) {
      max-width: 580px;
      margin: 0; } }
  &-Advantage {} }

.IntroAdvantage {
  @include mf(sm) {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &-Item {
    margin-bottom: 20px;
    color: $main-color;
    display: flex;
    align-items: center;
    @include mf(sm) {
      margin-bottom: 0; }
    &:nth-child(1) {
      .IntroAdvantage-IconWrap {
        width: 33px;
        height: 44px; } }
    &:nth-child(2) {
      .IntroAdvantage-IconWrap {
        width: 34px;
        height: 39px; } }
    &:last-of-type {
      margin-bottom: 0; } }
  &-IconWrap {
    margin-right: 25px; }
  &-Icon {
    max-width: 100%;
    max-height: 100%; }
  &-Text {
    font-size: 14px;
    line-height: 1.25;
    @include mf(sm) {
      font-size: 16px; } } }
