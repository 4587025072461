.Phone {
  &-Only {
    display: inherit !important;
    @include mf(xs) {
      display: none !important; } }
  &-Hidden {
    display: none !important;
    @include mf(xs) {
      display: inherit !important; } } }

@import "../../common.blocks/Btn/index";
@import "../../common.blocks/Btn/_disable/index";

@import "../../common.blocks/Input/index";
@import "../../common.blocks/Input/_error/index";

@import "../../common.blocks/Form/index";
@import "../../common.blocks/Form/_line/index";
@import "../../common.blocks/FormThx/index";

@import "../../common.blocks/ProcessingPersonalDataText/index";

@import "../../common.blocks/Logo/index";

@import "../../common.blocks/Link/index";

@import "../../common.blocks/Accordion/index";


@import "../../project.blocks/Input/index";

@import "../../project.blocks/Btn/index";

@import "../../project.blocks/Heading/index";

@import "../../project.blocks/HeaderNav/index";

@import "../../project.blocks/MyDefmenu/index";
@import "../../project.blocks/MyDefmenu/Nav/index";

@import "../../project.blocks/DocumentCard/index";

@import "../../project.blocks/Slider/index";

