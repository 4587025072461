.WeProtect {
  background-color: #242424;
  &-Body {
    padding-top: 50px;
    position: relative; }
  &-Card {
    position: relative;
    z-index: 3; }
  &-Simg {
    position: absolute;
    &_top {
      width: 650px;
      top: -170px;
      left: -115px;
      @include mf(sm) {
        width: auto;
        top: -420px;
        left: -200px; } }
    &_spark_left {
      width: 185px;
      left: -60px;
      bottom: -25px;
      @include mf(lg) {
        width: auto;
        left: -110px;
        bottom: -35px; } } } }

.WeProtectCard {
  padding: 15px 10px 30px;
  background-color: #ffffff;
  border-radius: 3px;
  @include mf(sm) {
    padding: 25px 25px 35px; }
  @include mf(sl) {
    padding: 40px 65px 55px 55px; }
  &-Heading {
    margin-bottom: 25px;
    @include mf(sm) {
      margin-bottom: 45px; } }
  &-Content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  &-Item {
    width: calc(50% - 4px);
    margin-top: 18px;
    @include mf(sx) {
      width: calc(33.33% - 4px); }
    @include mf(sm) {
      width: calc(33.33% - 20px); }
    @include mf(sl) {
      width: calc(25% - 20px);
      margin-top: 30px; }
    @include mf(lg) {
      width: calc(25% - 40px); }
    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 0; }
    &:nth-child(3) {
      @include mf(sx) {
        margin-top: 0; } }
    &:nth-child(4) {
      @include mf(sl) {
        margin-top: 0; } } }
  &-Object {} }

.WeProtectObject {
  &-ImgWrap {
    width: 100%;
    margin-bottom: 5px;
    border-radius: 3px;
    overflow: hidden;
    @include mf(sm) {
      margin-bottom: 10px; } }
  &-Img {
    width: 100%;
    display: block; }
  &-Title {
    padding-top: 3px;
    padding-left: 12px;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    @include mf(sm) {
      font-size: 16px; }
    &:before {
      content: '';
      width: 6px;
      height: 18px;
      background-color: $main-color;
      position: absolute;
      top: 0;
      left: 0;
      @include mf(sm) {
        width: 7px;
        height: 24px; } } } }
