.License {
  background-color: #242424;
  overflow: hidden;
  &-Body {
    padding-bottom: 55px;
    position: relative;
    z-index: 2; }
  &-Heading {
    margin-bottom: 25px;
    @include mf(sm) {
      margin-bottom: 50px; }
    @include mf(lg) {
      margin-bottom: 65px; } }
  &-Content {}
  &-Slider {
    .DocumentCard-PreviewWrap {
      @include mf(lg) {
        margin-bottom: 24px; } }
    .DocumentCard-Name {
      padding-left: 0;
      font-size: 14px;
      font-weight: 100;
      color: #FFFFFF;
      text-align: center;
      letter-spacing: .2px;
      @include mf(sl) {
        font-size: 16px; }
      &:before {
        content: '';
        width: 51px;
        height: 6px;
        margin: 0 auto 15px;
        position: static;
        display: block; } } }
  &-Item {}
  &-Card {
    margin: 0 auto; }
  &-Simg {
    display: none;
    @include mf(lg) {
      display: block;
      position: absolute;
      z-index: -1; }
    &_cloud {
      @include mf(lg) {
        top: calc(100% - 415px);
        right: -385px; } } } }

.LicenseSlider {
  @include mf(lg) {
    display: flex;
    flex-wrap: wrap; }
  &-Slide {
    @include mf(lg) {
      width: 350px;
      margin-top: 50px;
      margin-right: 35px; }
    &:nth-child(3n) {
      margin-right: 0; }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0; } } }
