@import "../../sass/bin/vars";

.Defmenu {
  &Content {
    height: 100%;
    transition: all linear .25s;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    display: inline-block;
    &::-webkit-scrollbar {
      width: 5px; }
    &::-webkit-scrollbar-thumb {
      background-color: $main-color;
      border-radius: 0;
      &:hover {
        background-color: darken($main-color, 10%); } }
    &::-webkit-scrollbar-track {
      background-color: rgb(237, 237, 237); } } }
