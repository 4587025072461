.Header {
  width: 100%;
  padding: 6px 0;
  background-color: #f0f0f0;
  position: fixed;
  z-index: 10;
  @include mf(sm) {
    padding: 0; }
  @include mf(sl) {
    background-color: #292929;
    position: relative; }
  &-Container {
    padding: 0;
    @include mf(sl) {
      padding: 0 15px 0 0; }
    @include mf(lg) {
      padding: 0 15px; } }
  &-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &-Logo {
    width: 79px;
    display: block;
    @include mf(sm) {
      width: 70px; }
    @include mf(sl) {
      width: 113px; } }
  &-LogoWrap {
    @include mf(sm) {
      padding: 6px 24px 6px 4px;
      background-color: #f0f0f0;
      display: flex;
      align-items: center; }
    &-Right {
      display: none;
      @include mf(sm) {
        margin-left: 17px;
        display: block; } }
    &-Description {
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: 900;
      color: #000;
      line-height: 1.1;
      text-transform: uppercase;
      @include mf(sl) {
        margin-bottom: 13px; }
      span {
        color: #6a6969; } }
    &-Location {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      display: flex;
      align-items: center;
      .Icon {
        width: 14px;
        height: 23px;
        margin-right: 10px; } } }
  &-About {} }

.HeaderAbout {
  display: none;
  @include mf(sl) {
    height: 90px;
    display: flex; }
  &-Item {
    @include mf(sl) {
      padding: 0 30px;
      border-right: 1px solid #3f3f3e;
      display: flex;
      align-items: center; }
    @include mf(lg) {
      padding: 0 50px; }
    &:last-of-type {
      border: none; } }
  &-Text {
    @include mf(sl) {
      font-size: 16px;
      color: #FFFFFF; } } }

.HeaderInfo {
  display: flex;
  flex-direction: column;
  &-Phone {
    margin-bottom: 5px;
    display: flex !important;
    &:last-of-type {
      margin-bottom: 0; } }
  &-Btn {
    display: none;
    @include mf(sl) {
      height: 37px;
      margin-top: 13px;
      display: flex; } } }

.HeaderPhone {
  width: 58px;
  height: 46px;
  background: #363636;
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mf(sm) {
    background-color: #292929; }
  @include mf(sl) {
    width: initial;
    height: initial;
    padding: initial;
    color: #000;
    background: initial;
    border: none;
    text-decoration: none;
    display: initial; }
  &:last-of-type {
    display: none !important;
    @include mf(sl) {
      display: flex !important; } }
  &-Number {
    display: none;
    @include mf(sl) {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center; }
    .Icon {
      width: 17px;
      height: 17px;
      margin-right: 8px;
      fill: $main-color; } }
  &-Img {
    width: 23px;
    height: 23px;
    fill: $main-color;
    display: block;
    @include mf(sl) {
      display: none; } } }

.DefmenuBtn {
  width: 58px;
  height: 46px;
  padding: 0;
  background-color: #363636;
  border-radius: 0 3px 3px 0;
  display: flex;
  justify-content: center;
  @include mf(sm) {
    background-color: #292929; }
  @include mf(sl) {
    display: none; }
  &-Line {
    width: 24px;
    height: 2px;
    margin-bottom: 4px;
    background-color: $main-color; } }

.DefmenuBtn_open {
  .DefmenuBtn-Line:nth-child(1) {
    top: calc(50% - 1px);
    left: calc(50% - 13px); }
  .DefmenuBtn-Line:nth-child(2) {
    display: none; }
  .DefmenuBtn-Line:nth-child(3) {
    top: calc(50% + 6px);
    right: calc(50% - 11px); } }

.HeaderPadding {
  padding-top: 89px;
  @include mf(sm) {
    padding-top: 80px; }
  @include mf(sl) {
    padding-top: 0; } }
