.Employee {
  background-color: #242424;
  &-Body {
    position: relative;
    @include mf(sl) {
      padding-top: 50px; } }
  &-Heading {
    margin-bottom: 30px;
    @include mf(sm) {
      margin-bottom: 70px; } }
  &-Content {
    position: relative;
    z-index: 3; }
  &-Item {
    margin-bottom: 55px;
    position: relative;
    @include mf(sm) {
      margin-bottom: 30px; }
    &:last-of-type {
      margin-bottom: 0;
      .Employee-Text {
        padding-bottom: 0;
        border-bottom: none; } } }
  &-Icon {
    fill: #656363;
    position: absolute;
    top: 0;
    left: 0;
    &_1 {
      width: 39px;
      height: 39px; }
    &_2 {
      width: 42px;
      height: 42px; }
    &_3 {
      width: 40px;
      height: 42px; }
    &_4 {
      width: 36px;
      height: 40px; } }
  &-Text {
    padding-left: 60px;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    @include mf(sm) {
      max-width: 695px;
      margin-left: 150px;
      padding-left: 0;
      padding-bottom: 30px;
      font-size: 18px;
      border-bottom: 1px solid #3b3b3b; }
    br {
      display: none;
      @include mf(xs) {
        display: block; } } }
  &-Simg {
    position: absolute;
    z-index: 3;
    &_soldier {
      width: 145px;
      right: -65px;
      bottom: -110px;
      @include mf(xs) {
        width: 200px; }
      @include mf(sx) {
        width: 250px; }
      @include mf(sm) {
        right: -35px;
        bottom: -70px; }
      @include mf(sl) {
        width: auto; }
      @include mf(lg) {
        right: 120px; } } } }
