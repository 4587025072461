.About {
  background-color: #242424;
  &-Body {
    padding-bottom: 55px;
    position: relative;
    z-index: 2; }
  &-Heading {
    margin-bottom: 15px;
    @include mf(sm) {
      margin-bottom: 30px; } }
  &-Subheading {
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 100;
    color: #FFFFFF;
    @include mf(sx) {
      max-width: 545px; }
    @include mf(sm) {
      max-width: 790px;
      margin-bottom: 85px;
      font-size: 24px; } }
  &-Content {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    @include mf(sm) {
      width: 90%;
      max-width: 670px;
      justify-content: space-between; } }
  &-Item {
    margin-bottom: 35px;
    @include mf(sm) {
      width: 40% !important;
      margin-bottom: 100px; }
    &:nth-last-child(2) {
      @include mf(sm) {
        margin-bottom: 0; } }
    &:last-of-type {
      margin-bottom: 0; } }
  &-Card {}
  &-Simg {
    position: absolute;
    &_soldier {
      width: 377px;
      left: 110px;
      bottom: -120px;
      @include mf(sm) {
        width: 500px;
        left: auto;
        right: -200px; }
      @include mf(sl) {
        right: -100px; }
      @include mf(lg) {
        width: auto;
        right: -400px;
        bottom: -410px; } } } }

.AboutCard {
  &-Icon {
    margin-bottom: 20px;
    fill: #656363; }
  &-Number {
    margin-bottom: 15px;
    font-size: 56px;
    color: $main-color;
    line-height: 0.8;
    @include mf(sm) {
      font-size: 72px; }
    span {
      font-size: 12px;
      @include mf(sm) {
        font-size: 16px; } } }
  &-Text {
    font-size: 14px;
    color: #FFFFFF;
    line-height: 1.25;
    @include mf(sm) {
      font-size: 16px; } } }

.About-Item {
  &:nth-child(1) {
    width: 40%;
    .AboutCard-Icon {
      width: 29px;
      height: 29px;
      @include mf(sm) {
        width: 37px;
        height: 38px; } } }
  &:nth-child(2) {
    width: 60%;
    .AboutCard-Icon {
      width: 29px;
      height: 36px;
      margin-bottom: 13px;
      @include mf(sm) {
        width: 38px;
        height: 46px; } } }
  &:nth-child(3) {
    width: 100%;
    .AboutCard-Icon {
      width: 33px;
      height: 33px;
      @include mf(sm) {
        width: 43px;
        height: 43px; } } }
  &:nth-child(4) {
    width: 130px;
    .AboutCard-Icon {
      width: 26px;
      height: 34px;
      @include mf(sm) {
        width: 33px;
        height: 44px; } } } }
