.Geography {
  background-color: #242424;
  &-Body {
    position: relative;
    z-index: 1; }
  &-Heading {
    margin-bottom: 10px;
    @include mf(sm) {
      margin-bottom: 50px; }
    @include mf(lg) {
      margin-bottom: 70px; } }
  &-Content {
    position: relative;
    z-index: 2; }
  &-Map {
    width: 100%;
    height: 300px;
    @include mf(sl) {
      height: 500px; } }
  &-Simg {
    position: absolute;
    &_spark {
      width: 300px;
      right: calc(100% - 195px);
      bottom: -80px;
      z-index: 0;
      @include mf(lg) {
        width: auto;
        left: -180px;
        right: auto;
        bottom: -45px; } }
    &_soldier {
      display: none;
      @include mf(lg) {
        display: block;
        top: -350px;
        right: -400px;
        z-index: -1; } } } }
