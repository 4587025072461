@charset "UTF-8";
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: https://meyerweb.com
	HTML5 Doctor				:: https://html5doctor.com
	and the HTML5 Boilerplate	:: https://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/*!* Responsive images and other embedded objects
*!
img,
object,
embed {max-width: 100%;}*/
/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul, ol {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

@font-face {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 100;
  src: url("/font/GothamProLight/GothamProLight.woff") format("woff"); }

@font-face {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  src: url("/font/GothamProRegular/GothamProRegular.woff") format("woff"); }

@font-face {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  src: url("/font/GothamProMedium/GothamProMedium.woff") format("woff"); }

@font-face {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 700;
  src: url("/font/GothamProBold/GothamProBold.woff") format("woff"); }

@font-face {
  font-family: "GothamProBold";
  font-style: normal;
  font-weight: 700;
  src: url("/font/GothamProBold/GothamProBold.woff") format("woff"); }

@font-face {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 900;
  src: url("/font/GothamProBlack/GothamProBlack.woff") format("woff"); }

* {
  outline: none; }

a, p, h1, sup, span {
  color: inherit;
  font-weight: inherit; }

sup {
  font-size: 60%; }

b {
  font-weight: 700; }

svg {
  fill: currentColor; }

body {
  margin: 0;
  padding: 0;
  font-family: "GothamPro", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #292929; }
  @media (min-width: 992px) {
    body {
      font-size: 16px; } }

.fonts-stage-1 body {
  font-family: "GothamPro", sans-serif;
  line-height: 1.25; }

.fonts-stage-2 body {
  font-family: "GothamPro", sans-serif;
  line-height: 1.25; }

.Container {
  width: 100%;
  max-width: 1150px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box; }

.ImCallback {
  background-color: #f0f0f0; }

.nowrap {
  white-space: nowrap; }

.fancybox-container {
  z-index: 9000; }

.fancybox-content {
  padding: 0; }

.SectionIndent {
  padding: 20px 0; }
  @media (min-width: 992px) {
    .SectionIndent {
      padding: 40px 0; } }

.Phone-Only {
  display: inherit !important; }
  @media (min-width: 480px) {
    .Phone-Only {
      display: none !important; } }

.Phone-Hidden {
  display: none !important; }
  @media (min-width: 480px) {
    .Phone-Hidden {
      display: inherit !important; } }

.Btn {
  font-size: 100%;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center; }

button.Btn {
  display: block;
  text-align: center; }

.Btn_disable {
  background-color: #C2C2C2 !important;
  font-size: 0 !important;
  cursor: not-allowed; }
  .Btn_disable:before {
    content: 'Ждите...';
    font-size: 14px; }

.Input {
  display: flex;
  flex-direction: column; }
  .Input-Control {
    padding-left: 20px;
    background-color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 5px; }

.Input_error {
  background-color: #fed1dd !important; }

.Form-Row {
  margin-bottom: 10px; }
  .Form-Row:last-child {
    margin-bottom: 0; }

.Form-Btn {
  width: 100%; }

@media (min-width: 575px) {
  .Form_line .Form-Row {
    display: flex; } }

.Form_line .Form-Column {
  margin-bottom: 10px; }
  @media (min-width: 575px) {
    .Form_line .Form-Column {
      margin-bottom: 0; } }
  .Form_line .Form-Column:last-child {
    margin-bottom: 0; }
  @media (min-width: 575px) {
    .Form_line .Form-Column_input {
      width: 60%; } }
  @media (min-width: 575px) {
    .Form_line .Form-Column_input .Input-Control {
      border-radius: 5px 0 0 5px; } }
  .Form_line .Form-Column_btn {
    height: 57px; }
    @media (min-width: 575px) {
      .Form_line .Form-Column_btn {
        width: 40%;
        height: 47px; } }
    .Form_line .Form-Column_btn .Form-Btn {
      width: 100%;
      height: 100%; }
      @media (min-width: 575px) {
        .Form_line .Form-Column_btn .Form-Btn {
          border-radius: 0 5px 5px 0; } }

.ProcessingPersonalDataText {
  margin-top: 10px;
  font-size: 12px;
  color: #ababab;
  text-align: center; }
  .ProcessingPersonalDataText-Link {
    color: #ababab;
    text-decoration: underline;
    display: inline; }

.Logo-Image {
  max-width: 100%;
  display: block; }

.Link {
  color: inherit;
  text-decoration: underline; }
  .Link:hover {
    text-decoration: none; }
  .Link-Dashed {
    border-bottom: 1px dashed #26a9e0; }
    .Link-Dashed:hover {
      border-bottom: none; }
  .Link-HoverLine {
    text-decoration: none; }
    .Link-HoverLine:hover {
      text-decoration: underline; }

.Accordion-Content {
  display: none; }

.Accordion-Item_open .Accordion-Content {
  display: block; }

.Input-Control {
  height: 47px; }

.Btn {
  height: 47px;
  background-color: #efdd64;
  font-size: 12px;
  font-family: 'GothamProBold';
  border-radius: 3px;
  text-transform: uppercase;
  -webkit-appearance: none; }
  .Btn:hover {
    background-color: #ead336; }

.Heading {
  font-weight: 900; }
  .Heading_lvl2 {
    font-size: 24px; }
    @media (min-width: 768px) {
      .Heading_lvl2 {
        font-size: 36px; } }
  .Heading .Heading_decorLine {
    position: relative;
    display: inline-block;
    z-index: 1; }
    .Heading .Heading_decorLine:before {
      content: '';
      width: 100%;
      height: 40%;
      background-color: #efdd64;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1; }
  .Heading_white {
    color: #FFFFFF; }

.HeaderNav {
  display: none; }
  @media (min-width: 992px) {
    .HeaderNav {
      width: 100%;
      background-color: #fff;
      display: block;
      position: relative;
      z-index: 3; } }
  .HeaderNav-Content {
    display: flex;
    justify-content: space-between; }
  .HeaderNav-Item {
    position: relative; }
    .HeaderNav-Item-Heading {
      padding: 20px 5px 16px;
      font-size: 14px;
      text-decoration: none;
      display: inline-block; }
      .HeaderNav-Item-Heading:hover {
        font-weight: 700;
        cursor: pointer; }
      .HeaderNav-Item-Heading:after {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden; }
      .HeaderNav-Item-Heading.active {
        background-color: #efdd64;
        font-weight: 700; }
    .HeaderNav-Item.HeaderNav-Item_active .HeaderNav-Item-Heading {
      font-weight: 700; }
    .HeaderNav-Item.HeaderNav-Item_active .HeaderNav-Drop {
      display: flex; }
  .HeaderNav-Drop {
    background-color: #f0f0f0;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0; }
    .HeaderNav-Drop-Link {
      padding: 7px 14px 5px;
      font-size: 14px;
      text-decoration: none;
      white-space: nowrap; }
      .HeaderNav-Drop-Link.active, .HeaderNav-Drop-Link:hover {
        background-color: #efdd64;
        font-weight: 700; }
      .HeaderNav-Drop-Link:after {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden; }

.DefmenuContent {
  height: 100%;
  transition: all linear .25s;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block; }
  .DefmenuContent::-webkit-scrollbar {
    width: 5px; }
  .DefmenuContent::-webkit-scrollbar-thumb {
    background-color: #efdd64;
    border-radius: 0; }
    .DefmenuContent::-webkit-scrollbar-thumb:hover {
      background-color: #ead336; }
  .DefmenuContent::-webkit-scrollbar-track {
    background-color: #ededed; }

.MyDefmenuNav {
  padding-top: 15px;
  padding-bottom: 30px;
  background-color: #f0f0f0; }
  .MyDefmenuNav-Link {
    width: 100%;
    padding: 10px 10px 10px 15px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    display: block; }
    .MyDefmenuNav-Link.active {
      background-color: #efdd64;
      color: #000; }
  .MyDefmenuNav-List_lvl2 {
    height: 0;
    overflow: hidden; }
    .MyDefmenuNav-List_lvl2.active {
      height: auto;
      overflow: initial; }
    .MyDefmenuNav-List_lvl2 .MyDefmenuNav-Item {
      margin-bottom: 0; }
    .MyDefmenuNav-List_lvl2 .MyDefmenuNav-Link {
      padding: 5px 10px 5px 30px;
      font-size: 13px;
      font-weight: 400;
      color: #000;
      display: block;
      position: relative; }
      .MyDefmenuNav-List_lvl2 .MyDefmenuNav-Link.active {
        background-color: #000;
        color: #efdd64;
        font-weight: bold; }

.DocumentCard {
  width: 255px; }
  @media (min-width: 1200px) {
    .DocumentCard {
      width: auto; } }
  .DocumentCard-PreviewWrap {
    width: 100%;
    height: 330px;
    margin-bottom: 18px;
    border: 8px solid #e5e5e5;
    border-radius: 3px;
    display: block; }
    @media (min-width: 1200px) {
      .DocumentCard-PreviewWrap {
        height: auto; } }
  .DocumentCard-Preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; }
  .DocumentCard-Name {
    padding-top: 5px;
    padding-left: 15px;
    font-size: 16px;
    color: #292929;
    letter-spacing: 0;
    position: relative; }
    .DocumentCard-Name:before {
      content: '';
      width: 4px;
      height: 100%;
      background-color: #efdd64;
      position: absolute;
      top: 0;
      left: 0; }

.Slider {
  position: relative; }
  .Slider-Arrow {
    width: 46px;
    height: 46px;
    background-color: #efdd64;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 23px);
    z-index: 3; }
    .Slider-Arrow:hover {
      background-color: #ead336; }
    .Slider-Arrow_prev {
      left: 0; }
    .Slider-Arrow_next {
      right: 0;
      transform: rotate(180deg); }
    .Slider-Arrow .Icon {
      width: 12px;
      height: 19px;
      margin-right: 5px;
      fill: #fff; }

.Slider_document {
  width: 285px;
  margin: 0 auto; }
  @media (min-width: 575px) {
    .Slider_document {
      width: 570px; } }
  @media (min-width: 992px) {
    .Slider_document {
      width: 855px; } }
  @media (min-width: 1200px) {
    .Slider_document {
      width: auto; } }
  .Slider_document .Slider-Arrow {
    top: 140px; }

.Header {
  width: 100%;
  padding: 6px 0;
  background-color: #f0f0f0;
  position: fixed;
  z-index: 10; }
  @media (min-width: 768px) {
    .Header {
      padding: 0; } }
  @media (min-width: 992px) {
    .Header {
      background-color: #292929;
      position: relative; } }
  .Header-Container {
    padding: 0; }
    @media (min-width: 992px) {
      .Header-Container {
        padding: 0 15px 0 0; } }
    @media (min-width: 1200px) {
      .Header-Container {
        padding: 0 15px; } }
  .Header-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .Header-Logo {
    width: 79px;
    display: block; }
    @media (min-width: 768px) {
      .Header-Logo {
        width: 70px; } }
    @media (min-width: 992px) {
      .Header-Logo {
        width: 113px; } }
  @media (min-width: 768px) {
    .Header-LogoWrap {
      padding: 6px 24px 6px 4px;
      background-color: #f0f0f0;
      display: flex;
      align-items: center; } }
  .Header-LogoWrap-Right {
    display: none; }
    @media (min-width: 768px) {
      .Header-LogoWrap-Right {
        margin-left: 17px;
        display: block; } }
  .Header-LogoWrap-Description {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 900;
    color: #000;
    line-height: 1.1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .Header-LogoWrap-Description {
        margin-bottom: 13px; } }
    .Header-LogoWrap-Description span {
      color: #6a6969; }
  .Header-LogoWrap-Location {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center; }
    .Header-LogoWrap-Location .Icon {
      width: 14px;
      height: 23px;
      margin-right: 10px; }

.HeaderAbout {
  display: none; }
  @media (min-width: 992px) {
    .HeaderAbout {
      height: 90px;
      display: flex; } }
  @media (min-width: 992px) {
    .HeaderAbout-Item {
      padding: 0 30px;
      border-right: 1px solid #3f3f3e;
      display: flex;
      align-items: center; } }
  @media (min-width: 1200px) {
    .HeaderAbout-Item {
      padding: 0 50px; } }
  .HeaderAbout-Item:last-of-type {
    border: none; }
  @media (min-width: 992px) {
    .HeaderAbout-Text {
      font-size: 16px;
      color: #FFFFFF; } }

.HeaderInfo {
  display: flex;
  flex-direction: column; }
  .HeaderInfo-Phone {
    margin-bottom: 5px;
    display: flex !important; }
    .HeaderInfo-Phone:last-of-type {
      margin-bottom: 0; }
  .HeaderInfo-Btn {
    display: none; }
    @media (min-width: 992px) {
      .HeaderInfo-Btn {
        height: 37px;
        margin-top: 13px;
        display: flex; } }

.HeaderPhone {
  width: 58px;
  height: 46px;
  background: #363636;
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 768px) {
    .HeaderPhone {
      background-color: #292929; } }
  @media (min-width: 992px) {
    .HeaderPhone {
      width: initial;
      height: initial;
      padding: initial;
      color: #000;
      background: initial;
      border: none;
      text-decoration: none;
      display: initial; } }
  .HeaderPhone:last-of-type {
    display: none !important; }
    @media (min-width: 992px) {
      .HeaderPhone:last-of-type {
        display: flex !important; } }
  .HeaderPhone-Number {
    display: none; }
    @media (min-width: 992px) {
      .HeaderPhone-Number {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center; } }
    .HeaderPhone-Number .Icon {
      width: 17px;
      height: 17px;
      margin-right: 8px;
      fill: #efdd64; }
  .HeaderPhone-Img {
    width: 23px;
    height: 23px;
    fill: #efdd64;
    display: block; }
    @media (min-width: 992px) {
      .HeaderPhone-Img {
        display: none; } }

.DefmenuBtn {
  width: 58px;
  height: 46px;
  padding: 0;
  background-color: #363636;
  border-radius: 0 3px 3px 0;
  display: flex;
  justify-content: center; }
  @media (min-width: 768px) {
    .DefmenuBtn {
      background-color: #292929; } }
  @media (min-width: 992px) {
    .DefmenuBtn {
      display: none; } }
  .DefmenuBtn-Line {
    width: 24px;
    height: 2px;
    margin-bottom: 4px;
    background-color: #efdd64; }

.DefmenuBtn_open .DefmenuBtn-Line:nth-child(1) {
  top: calc(50% - 1px);
  left: calc(50% - 13px); }

.DefmenuBtn_open .DefmenuBtn-Line:nth-child(2) {
  display: none; }

.DefmenuBtn_open .DefmenuBtn-Line:nth-child(3) {
  top: calc(50% + 6px);
  right: calc(50% - 11px); }

.HeaderPadding {
  padding-top: 89px; }
  @media (min-width: 768px) {
    .HeaderPadding {
      padding-top: 80px; } }
  @media (min-width: 992px) {
    .HeaderPadding {
      padding-top: 0; } }

.Intro {
  background-color: #292929; }
  .Intro-Body {
    padding-top: 30px;
    padding-bottom: 55px;
    position: relative; }
    @media (min-width: 768px) {
      .Intro-Body {
        padding-top: 70px;
        padding-bottom: 125px; } }
  .Intro-Content {
    max-width: 320px;
    margin: 0 auto;
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      .Intro-Content {
        max-width: 60%;
        margin: 0;
        padding-right: 45px; } }
    @media (min-width: 1200px) {
      .Intro-Content {
        max-width: 57%; } }
  .Intro-Heading {
    font-size: 30px;
    font-weight: 700;
    color: #FFFFFF; }
    @media (min-width: 992px) {
      .Intro-Heading {
        font-size: 40px; } }
  .Intro-Subheading {
    font-size: 24px;
    font-weight: 700;
    color: #9e9e9e; }
    @media (min-width: 992px) {
      .Intro-Subheading {
        font-size: 27px; } }
    @media (min-width: 1200px) {
      .Intro-Subheading {
        font-size: 30px; } }
  .Intro-Player {
    width: 100%;
    max-width: 320px;
    height: 176px;
    margin: 0 auto;
    position: relative; }
    @media (min-width: 768px) {
      .Intro-Player {
        position: absolute;
        top: 55px;
        right: 0;
        transform: translateX(100%); } }
    @media (min-width: 992px) {
      .Intro-Player {
        max-width: 370px;
        height: 205px; } }
    @media (min-width: 1200px) {
      .Intro-Player {
        max-width: 470px;
        height: 270px;
        top: 0; } }
    .Intro-Player-Video {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      z-index: 2; }
  .Intro-Simg {
    position: absolute; }
    .Intro-Simg_cloud_left {
      display: none; }
      @media (min-width: 1200px) {
        .Intro-Simg_cloud_left {
          display: block;
          top: 170px;
          right: calc(100% - 25px); } }
    .Intro-Simg_cloud_right {
      width: 203px;
      right: -25px;
      bottom: 0; }
      @media (min-width: 1200px) {
        .Intro-Simg_cloud_right {
          width: auto;
          right: -300px; } }
  .Intro-SpecText {
    font-size: 90px;
    font-weight: 900;
    color: #FFFFFF;
    opacity: 0.02;
    line-height: 0.75;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%); }
    @media (min-width: 768px) {
      .Intro-SpecText {
        left: auto;
        right: 0;
        transform: none; } }
    @media (min-width: 1200px) {
      .Intro-SpecText {
        font-size: 250px;
        right: -400px;
        bottom: -20px; } }
  .Intro-Form {
    max-width: 320px;
    margin: 0 auto;
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      .Intro-Form {
        max-width: 580px;
        margin: 0; } }

@media (min-width: 768px) {
  .IntroAdvantage {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

.IntroAdvantage-Item {
  margin-bottom: 20px;
  color: #efdd64;
  display: flex;
  align-items: center; }
  @media (min-width: 768px) {
    .IntroAdvantage-Item {
      margin-bottom: 0; } }
  .IntroAdvantage-Item:nth-child(1) .IntroAdvantage-IconWrap {
    width: 33px;
    height: 44px; }
  .IntroAdvantage-Item:nth-child(2) .IntroAdvantage-IconWrap {
    width: 34px;
    height: 39px; }
  .IntroAdvantage-Item:last-of-type {
    margin-bottom: 0; }

.IntroAdvantage-IconWrap {
  margin-right: 25px; }

.IntroAdvantage-Icon {
  max-width: 100%;
  max-height: 100%; }

.IntroAdvantage-Text {
  font-size: 14px;
  line-height: 1.25; }
  @media (min-width: 768px) {
    .IntroAdvantage-Text {
      font-size: 16px; } }

.Employee {
  background-color: #242424; }
  .Employee-Body {
    position: relative; }
    @media (min-width: 992px) {
      .Employee-Body {
        padding-top: 50px; } }
  .Employee-Heading {
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .Employee-Heading {
        margin-bottom: 70px; } }
  .Employee-Content {
    position: relative;
    z-index: 3; }
  .Employee-Item {
    margin-bottom: 55px;
    position: relative; }
    @media (min-width: 768px) {
      .Employee-Item {
        margin-bottom: 30px; } }
    .Employee-Item:last-of-type {
      margin-bottom: 0; }
      .Employee-Item:last-of-type .Employee-Text {
        padding-bottom: 0;
        border-bottom: none; }
  .Employee-Icon {
    fill: #656363;
    position: absolute;
    top: 0;
    left: 0; }
    .Employee-Icon_1 {
      width: 39px;
      height: 39px; }
    .Employee-Icon_2 {
      width: 42px;
      height: 42px; }
    .Employee-Icon_3 {
      width: 40px;
      height: 42px; }
    .Employee-Icon_4 {
      width: 36px;
      height: 40px; }
  .Employee-Text {
    padding-left: 60px;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.1px;
    color: #FFFFFF; }
    @media (min-width: 768px) {
      .Employee-Text {
        max-width: 695px;
        margin-left: 150px;
        padding-left: 0;
        padding-bottom: 30px;
        font-size: 18px;
        border-bottom: 1px solid #3b3b3b; } }
    .Employee-Text br {
      display: none; }
      @media (min-width: 480px) {
        .Employee-Text br {
          display: block; } }
  .Employee-Simg {
    position: absolute;
    z-index: 3; }
    .Employee-Simg_soldier {
      width: 145px;
      right: -65px;
      bottom: -110px; }
      @media (min-width: 480px) {
        .Employee-Simg_soldier {
          width: 200px; } }
      @media (min-width: 575px) {
        .Employee-Simg_soldier {
          width: 250px; } }
      @media (min-width: 768px) {
        .Employee-Simg_soldier {
          right: -35px;
          bottom: -70px; } }
      @media (min-width: 992px) {
        .Employee-Simg_soldier {
          width: auto; } }
      @media (min-width: 1200px) {
        .Employee-Simg_soldier {
          right: 120px; } }

.Photogal {
  background-color: #242424; }
  .Photogal-Body {
    position: relative; }
  .Photogal-Card {
    padding: 15px 9px 85px;
    background-color: #f0f0f0;
    border-radius: 3px;
    position: relative;
    z-index: 3; }
    @media (min-width: 992px) {
      .Photogal-Card {
        padding: 25px 35px 85px; } }
    @media (min-width: 1200px) {
      .Photogal-Card {
        padding: 25px 35px 50px; } }
  .Photogal-Heading {
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .Photogal-Heading {
        margin-bottom: 30px; } }
  .Photogal-Subheading {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.1px; }
    @media (min-width: 768px) {
      .Photogal-Subheading {
        margin-bottom: 30px; } }
  .Photogal-Slider {
    width: 100%; }

@media (min-width: 1200px) {
  .PhotogalSlider {
    display: flex;
    flex-wrap: wrap; } }

.PhotogalSlider-Slide {
  width: 100%;
  margin: 0 5px; }
  @media (min-width: 1200px) {
    .PhotogalSlider-Slide {
      width: calc(33.33% - 20px*2/3);
      margin-top: 20px;
      margin-left: 0;
      margin-right: 20px; } }
  @media (min-width: 1200px) {
    .PhotogalSlider-Slide:nth-child(1) {
      margin-top: 0; } }
  @media (min-width: 1200px) {
    .PhotogalSlider-Slide:nth-child(2) {
      margin-top: 0; } }
  @media (min-width: 1200px) {
    .PhotogalSlider-Slide:nth-child(3) {
      margin-top: 0; } }
  @media (min-width: 1200px) {
    .PhotogalSlider-Slide:nth-child(3n) {
      margin-right: 0; } }

.PhotogalSlider-Link {
  width: 100%;
  display: block; }

.PhotogalSlider-Img {
  width: 100%;
  border-radius: 3px;
  display: block; }

.PhotogalSlider .Slider-Arrow {
  top: calc(100% + 10px); }

.WeProtect {
  background-color: #242424; }
  .WeProtect-Body {
    padding-top: 50px;
    position: relative; }
  .WeProtect-Card {
    position: relative;
    z-index: 3; }
  .WeProtect-Simg {
    position: absolute; }
    .WeProtect-Simg_top {
      width: 650px;
      top: -170px;
      left: -115px; }
      @media (min-width: 768px) {
        .WeProtect-Simg_top {
          width: auto;
          top: -420px;
          left: -200px; } }
    .WeProtect-Simg_spark_left {
      width: 185px;
      left: -60px;
      bottom: -25px; }
      @media (min-width: 1200px) {
        .WeProtect-Simg_spark_left {
          width: auto;
          left: -110px;
          bottom: -35px; } }

.WeProtectCard {
  padding: 15px 10px 30px;
  background-color: #ffffff;
  border-radius: 3px; }
  @media (min-width: 768px) {
    .WeProtectCard {
      padding: 25px 25px 35px; } }
  @media (min-width: 992px) {
    .WeProtectCard {
      padding: 40px 65px 55px 55px; } }
  .WeProtectCard-Heading {
    margin-bottom: 25px; }
    @media (min-width: 768px) {
      .WeProtectCard-Heading {
        margin-bottom: 45px; } }
  .WeProtectCard-Content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .WeProtectCard-Item {
    width: calc(50% - 4px);
    margin-top: 18px; }
    @media (min-width: 575px) {
      .WeProtectCard-Item {
        width: calc(33.33% - 4px); } }
    @media (min-width: 768px) {
      .WeProtectCard-Item {
        width: calc(33.33% - 20px); } }
    @media (min-width: 992px) {
      .WeProtectCard-Item {
        width: calc(25% - 20px);
        margin-top: 30px; } }
    @media (min-width: 1200px) {
      .WeProtectCard-Item {
        width: calc(25% - 40px); } }
    .WeProtectCard-Item:nth-child(1), .WeProtectCard-Item:nth-child(2) {
      margin-top: 0; }
    @media (min-width: 575px) {
      .WeProtectCard-Item:nth-child(3) {
        margin-top: 0; } }
    @media (min-width: 992px) {
      .WeProtectCard-Item:nth-child(4) {
        margin-top: 0; } }

.WeProtectObject-ImgWrap {
  width: 100%;
  margin-bottom: 5px;
  border-radius: 3px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .WeProtectObject-ImgWrap {
      margin-bottom: 10px; } }

.WeProtectObject-Img {
  width: 100%;
  display: block; }

.WeProtectObject-Title {
  padding-top: 3px;
  padding-left: 12px;
  font-size: 12px;
  font-weight: 500;
  position: relative; }
  @media (min-width: 768px) {
    .WeProtectObject-Title {
      font-size: 16px; } }
  .WeProtectObject-Title:before {
    content: '';
    width: 6px;
    height: 18px;
    background-color: #efdd64;
    position: absolute;
    top: 0;
    left: 0; }
    @media (min-width: 768px) {
      .WeProtectObject-Title:before {
        width: 7px;
        height: 24px; } }

.Calculation {
  background-color: #242424; }
  .Calculation-Body {
    padding-bottom: 110px; }
    @media (min-width: 768px) {
      .Calculation-Body {
        padding-bottom: 70px; } }
  .Calculation-Heading {
    font-size: 30px;
    font-weight: 900;
    color: #efdd64;
    text-transform: uppercase;
    position: relative;
    z-index: 3; }
    @media (min-width: 768px) {
      .Calculation-Heading {
        font-size: 40px; } }
  .Calculation-Subheading {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 900;
    color: #ffffff;
    position: relative;
    z-index: 3; }
    @media (min-width: 768px) {
      .Calculation-Subheading {
        margin-bottom: 40px;
        font-size: 36px; } }
  .Calculation-Content {
    position: relative; }
  .Calculation-Card {
    max-width: 340px; }
    @media (min-width: 768px) {
      .Calculation-Card {
        max-width: none; } }

.CalculationCard {
  padding: 25px 10px 140px;
  background-color: #292929;
  border-radius: 3px;
  box-shadow: 0px 31px 59.5px 10.5px rgba(239, 221, 100, 0.11);
  position: relative;
  z-index: 2; }
  @media (min-width: 768px) {
    .CalculationCard {
      padding: 45px 60px 55px; } }
  .CalculationCard-Content {
    max-width: 320px;
    position: relative;
    z-index: 2; }
  .CalculationCard-Form .Form-Row {
    margin-bottom: 20px; }
    .CalculationCard-Form .Form-Row:last-of-type {
      margin-bottom: 0; }
  .CalculationCard-Simg {
    position: absolute; }
    .CalculationCard-Simg_cloud-left {
      top: -40px;
      left: -65px; }
      @media (min-width: 768px) {
        .CalculationCard-Simg_cloud-left {
          left: -90px; } }

.About {
  background-color: #242424; }
  .About-Body {
    padding-bottom: 55px;
    position: relative;
    z-index: 2; }
  .About-Heading {
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .About-Heading {
        margin-bottom: 30px; } }
  .About-Subheading {
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 100;
    color: #FFFFFF; }
    @media (min-width: 575px) {
      .About-Subheading {
        max-width: 545px; } }
    @media (min-width: 768px) {
      .About-Subheading {
        max-width: 790px;
        margin-bottom: 85px;
        font-size: 24px; } }
  .About-Content {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      .About-Content {
        width: 90%;
        max-width: 670px;
        justify-content: space-between; } }
  .About-Item {
    margin-bottom: 35px; }
    @media (min-width: 768px) {
      .About-Item {
        width: 40% !important;
        margin-bottom: 100px; } }
    @media (min-width: 768px) {
      .About-Item:nth-last-child(2) {
        margin-bottom: 0; } }
    .About-Item:last-of-type {
      margin-bottom: 0; }
  .About-Simg {
    position: absolute; }
    .About-Simg_soldier {
      width: 377px;
      left: 110px;
      bottom: -120px; }
      @media (min-width: 768px) {
        .About-Simg_soldier {
          width: 500px;
          left: auto;
          right: -200px; } }
      @media (min-width: 992px) {
        .About-Simg_soldier {
          right: -100px; } }
      @media (min-width: 1200px) {
        .About-Simg_soldier {
          width: auto;
          right: -400px;
          bottom: -410px; } }

.AboutCard-Icon {
  margin-bottom: 20px;
  fill: #656363; }

.AboutCard-Number {
  margin-bottom: 15px;
  font-size: 56px;
  color: #efdd64;
  line-height: 0.8; }
  @media (min-width: 768px) {
    .AboutCard-Number {
      font-size: 72px; } }
  .AboutCard-Number span {
    font-size: 12px; }
    @media (min-width: 768px) {
      .AboutCard-Number span {
        font-size: 16px; } }

.AboutCard-Text {
  font-size: 14px;
  color: #FFFFFF;
  line-height: 1.25; }
  @media (min-width: 768px) {
    .AboutCard-Text {
      font-size: 16px; } }

.About-Item:nth-child(1) {
  width: 40%; }
  .About-Item:nth-child(1) .AboutCard-Icon {
    width: 29px;
    height: 29px; }
    @media (min-width: 768px) {
      .About-Item:nth-child(1) .AboutCard-Icon {
        width: 37px;
        height: 38px; } }

.About-Item:nth-child(2) {
  width: 60%; }
  .About-Item:nth-child(2) .AboutCard-Icon {
    width: 29px;
    height: 36px;
    margin-bottom: 13px; }
    @media (min-width: 768px) {
      .About-Item:nth-child(2) .AboutCard-Icon {
        width: 38px;
        height: 46px; } }

.About-Item:nth-child(3) {
  width: 100%; }
  .About-Item:nth-child(3) .AboutCard-Icon {
    width: 33px;
    height: 33px; }
    @media (min-width: 768px) {
      .About-Item:nth-child(3) .AboutCard-Icon {
        width: 43px;
        height: 43px; } }

.About-Item:nth-child(4) {
  width: 130px; }
  .About-Item:nth-child(4) .AboutCard-Icon {
    width: 26px;
    height: 34px; }
    @media (min-width: 768px) {
      .About-Item:nth-child(4) .AboutCard-Icon {
        width: 33px;
        height: 44px; } }

.Partners {
  background-color: #242424; }
  .Partners-Body {
    position: relative;
    z-index: 2; }
  .Partners-Heading {
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .Partners-Heading {
        margin-bottom: 50px; } }
    @media (min-width: 1200px) {
      .Partners-Heading {
        margin-bottom: 70px; } }
  .Partners-Card {
    padding: 15px 13px 20px;
    background-color: #ffffff;
    border-radius: 3px; }
    @media (min-width: 768px) {
      .Partners-Card {
        padding: 40px 30px 50px; } }
  .Partners-Content {
    display: flex;
    flex-wrap: wrap; }
  .Partners-Item {
    width: 50%;
    height: 85px;
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 575px) {
      .Partners-Item {
        width: 33.33%; } }
    @media (min-width: 992px) {
      .Partners-Item {
        height: 155px; } }
    @media (min-width: 1200px) {
      .Partners-Item {
        width: 25%; } }
    .Partners-Item:nth-child(1), .Partners-Item:nth-child(2) {
      border-top: none; }
    @media (min-width: 575px) {
      .Partners-Item:nth-child(3) {
        border-top: none; } }
    @media (min-width: 1200px) {
      .Partners-Item:nth-child(4) {
        border-top: none; } }
    .Partners-Item:nth-child(2n) {
      border-right: none; }
      @media (min-width: 575px) {
        .Partners-Item:nth-child(2n) {
          border-right: 1px solid #e8e8e8; } }
    @media (min-width: 575px) {
      .Partners-Item:nth-child(3n) {
        border-right: none; } }
    @media (min-width: 1200px) {
      .Partners-Item:nth-child(3n) {
        border-right: 1px solid #e8e8e8; } }
    @media (min-width: 1200px) {
      .Partners-Item:nth-child(4n) {
        border-right: none; } }
  .Partners-Img {
    max-width: 65%;
    max-height: 65%;
    display: block; }
  .Partners-Simg {
    display: none;
    position: absolute; }
    @media (min-width: 1200px) {
      .Partners-Simg {
        display: block;
        top: -280px;
        right: -266px;
        z-index: -1; } }

.Geography {
  background-color: #242424; }
  .Geography-Body {
    position: relative;
    z-index: 1; }
  .Geography-Heading {
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .Geography-Heading {
        margin-bottom: 50px; } }
    @media (min-width: 1200px) {
      .Geography-Heading {
        margin-bottom: 70px; } }
  .Geography-Content {
    position: relative;
    z-index: 2; }
  .Geography-Map {
    width: 100%;
    height: 300px; }
    @media (min-width: 992px) {
      .Geography-Map {
        height: 500px; } }
  .Geography-Simg {
    position: absolute; }
    .Geography-Simg_spark {
      width: 300px;
      right: calc(100% - 195px);
      bottom: -80px;
      z-index: 0; }
      @media (min-width: 1200px) {
        .Geography-Simg_spark {
          width: auto;
          left: -180px;
          right: auto;
          bottom: -45px; } }
    .Geography-Simg_soldier {
      display: none; }
      @media (min-width: 1200px) {
        .Geography-Simg_soldier {
          display: block;
          top: -350px;
          right: -400px;
          z-index: -1; } }

.License {
  background-color: #242424;
  overflow: hidden; }
  .License-Body {
    padding-bottom: 55px;
    position: relative;
    z-index: 2; }
  .License-Heading {
    margin-bottom: 25px; }
    @media (min-width: 768px) {
      .License-Heading {
        margin-bottom: 50px; } }
    @media (min-width: 1200px) {
      .License-Heading {
        margin-bottom: 65px; } }
  @media (min-width: 1200px) {
    .License-Slider .DocumentCard-PreviewWrap {
      margin-bottom: 24px; } }
  .License-Slider .DocumentCard-Name {
    padding-left: 0;
    font-size: 14px;
    font-weight: 100;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: .2px; }
    @media (min-width: 992px) {
      .License-Slider .DocumentCard-Name {
        font-size: 16px; } }
    .License-Slider .DocumentCard-Name:before {
      content: '';
      width: 51px;
      height: 6px;
      margin: 0 auto 15px;
      position: static;
      display: block; }
  .License-Card {
    margin: 0 auto; }
  .License-Simg {
    display: none; }
    @media (min-width: 1200px) {
      .License-Simg {
        display: block;
        position: absolute;
        z-index: -1; } }
    @media (min-width: 1200px) {
      .License-Simg_cloud {
        top: calc(100% - 415px);
        right: -385px; } }

@media (min-width: 1200px) {
  .LicenseSlider {
    display: flex;
    flex-wrap: wrap; } }

@media (min-width: 1200px) {
  .LicenseSlider-Slide {
    width: 350px;
    margin-top: 50px;
    margin-right: 35px; } }

.LicenseSlider-Slide:nth-child(3n) {
  margin-right: 0; }

.LicenseSlider-Slide:nth-child(1), .LicenseSlider-Slide:nth-child(2), .LicenseSlider-Slide:nth-child(3) {
  margin-top: 0; }

@media (min-width: 1200px) {
  .Feedback-Body {
    position: relative; } }

.Feedback-Heading {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .Feedback-Heading {
      margin-bottom: 50px; } }

@media (min-width: 1200px) {
  .Feedback-Content {
    position: relative;
    z-index: 2; } }

.Feedback-Simg {
  display: none; }
  @media (min-width: 1200px) {
    .Feedback-Simg {
      display: block;
      position: absolute; } }
  @media (min-width: 1200px) {
    .Feedback-Simg_cloud {
      top: -90px;
      right: calc(100% + 55px); } }
  .Feedback-Simg_spark {
    right: -215px;
    bottom: 50px; }

.Feedback-Item {
  padding: 0 15px; }
  @media (min-width: 1200px) {
    .Feedback-Item {
      padding: 0; } }

.Feedback-Card {
  margin: 0 auto; }

.FeedbackSlider {
  position: relative; }
  @media (min-width: 1200px) {
    .FeedbackSlider {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 1200px) {
    .FeedbackSlider-Slide {
      width: 265px;
      margin-top: 50px;
      margin-right: 20px; } }
  .FeedbackSlider-Slide:nth-child(4n) {
    margin-right: 0; }
  .FeedbackSlider-Slide:nth-child(1), .FeedbackSlider-Slide:nth-child(2), .FeedbackSlider-Slide:nth-child(3), .FeedbackSlider-Slide:nth-child(4) {
    margin-top: 0; }

.FAQ {
  background-color: #292929; }
  .FAQ-Heading {
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .FAQ-Heading {
        margin-bottom: 35px; } }

.FAQCard-Item {
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .FAQCard-Item {
      margin-bottom: 20px; } }
  .FAQCard-Item:last-of-type {
    margin-bottom: 0; }

.FAQCard-Title {
  padding-left: 20px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 600;
  position: relative;
  display: inline-block;
  cursor: pointer; }
  @media (min-width: 992px) {
    .FAQCard-Title {
      font-size: 18px; } }
  .FAQCard-Title:before {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid #efdd64;
    position: absolute;
    top: 4px;
    left: 0; }

.FAQCard-Content {
  margin-top: 15px;
  margin-left: 20px;
  padding: 15px 12px 15px 20px;
  background-color: #e2e2e2;
  border-radius: 3px; }
  @media (min-width: 768px) {
    .FAQCard-Content {
      padding-bottom: 20px; } }

.FAQCard p {
  margin-bottom: 15px;
  font-size: 14px; }
  .FAQCard p:last-of-type {
    margin-bottom: 0; }
  .FAQCard p b {
    font-size: 16px;
    display: block; }

.Accordion-Item_open .FAQCard-Title:before {
  transform: rotate(-90deg); }

.Contact-Content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .Contact-Content {
      width: calc(50% - 20px);
      display: block;
      position: relative; } }

.Contact-Heading {
  width: 100%;
  margin-bottom: 25px; }
  @media (min-width: 992px) {
    .Contact-Heading {
      margin-bottom: 40px; } }

.Contact-Info {
  width: 100%;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .Contact-Info {
      margin-bottom: 50px; } }

.Contact-MapWrap {
  width: 100%;
  height: 300px;
  margin-bottom: 25px; }
  @media (min-width: 575px) {
    .Contact-MapWrap {
      width: 50%;
      height: auto;
      margin: 0;
      order: 2; } }
  @media (min-width: 992px) {
    .Contact-MapWrap {
      width: calc(100% + 20px);
      height: 100%;
      position: absolute;
      top: 0;
      left: calc(100% + 20px); } }

.Contact-Map {
  width: 100%;
  height: 100%; }
  .Contact-Map .ymaps-2-1-74-map {
    height: 100%; }

@media (min-width: 480px) {
  .ContactInfo {
    display: flex; } }

.ContactInfo-Item {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .ContactInfo-Item {
      margin-right: 50px;
      margin-bottom: 0; } }
  .ContactInfo-Item:last-of-type {
    margin: 0; }

.ContactInfo-Icon {
  fill: #efdd64;
  position: absolute;
  top: 0;
  left: 0; }

.ContactInfo-Phone {
  padding-left: 35px;
  font-size: 18px;
  font-weight: 600;
  position: relative; }
  .ContactInfo-Phone .ContactInfo-Icon {
    width: 18px;
    height: 18px;
    top: 0; }

.ContactInfo-Location {
  padding-left: 35px;
  font-size: 17px;
  position: relative; }
  .ContactInfo-Location .ContactInfo-Icon {
    width: 20px;
    height: 31px;
    top: 2px; }

.ContactFormCard {
  width: 100%;
  padding: 30px 12px 40px;
  background-color: #292929;
  border-radius: 3px; }
  @media (min-width: 575px) {
    .ContactFormCard {
      width: calc(50% - 20px);
      order: 1; } }
  @media (min-width: 992px) {
    .ContactFormCard {
      width: 100%; } }
  .ContactFormCard-Title {
    max-width: 310px;
    margin: 0 auto 30px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center; }
    @media (min-width: 992px) {
      .ContactFormCard-Title {
        font-size: 18px; } }
  .ContactFormCard-Form {
    max-width: 310px;
    margin: 0 auto; }
  .ContactFormCard-Simg {
    display: none; }

.ImPolitic {
  max-width: 600px;
  padding: 20px;
  font-weight: 300; }
  .ImPolitic-Title {
    font-size: 20px;
    font-weight: bold; }

.ImCallback {
  width: auto;
  max-width: 320px;
  padding: 20px 10px; }
  @media (min-width: 480px) {
    .ImCallback {
      width: 320px;
      max-width: none;
      padding: 30px 20px 20px; } }
  .ImCallback-Title {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #efdd64;
    text-align: center;
    text-transform: uppercase; }
  .ImCallback-Subtitle {
    margin-bottom: 20px;
    font-size: 14px;
    color: #444444;
    text-align: center; }
  .ImCallback-Input {
    margin-bottom: 10px; }
  .ImCallback-Btn {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
    @media (min-width: 480px) {
      .ImCallback-Btn {
        padding-left: inherit;
        padding-right: inherit; } }

.ImThanks {
  padding: 20px;
  display: none; }
  .ImThanks-Title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #efdd64;
    text-align: center;
    text-transform: uppercase; }
  .ImThanks-Subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    color: #444444;
    text-align: center; }
  .ImThanks-Btn {
    width: 100%; }

.Footer {
  padding: 17px 0 16px;
  background-color: #333333; }
  .Footer-Body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column; }
    @media (min-width: 480px) {
      .Footer-Body {
        flex-direction: row; } }
  .Footer-Politic {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0.5px; }
    @media (min-width: 480px) {
      .Footer-Politic {
        margin-bottom: 10px; } }
  .Footer-FijiLogo {
    width: 205px; }
    .Footer-FijiLogo img {
      max-width: 100%;
      height: auto;
      display: inline-block; }

.Footer {
  background-color: #292929; }
  .Footer-Politic {
    color: #a8adaf; }
