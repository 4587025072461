.Form_line {
  .Form {
    &-Row {
      @include mf(sx) {
        display: flex; } }
    &-Column {
      margin-bottom: 10px;
      @include mf(sx) {
        margin-bottom: 0; }
      &:last-child {
        margin-bottom: 0; }
      &_input {
        @include mf(sx) {
          width: 60%; }
        .Input-Control {
          @include mf(sx) {
            border-radius: 5px 0 0 5px; } } }
      &_btn {
        height: 57px;
        @include mf(sx) {
          width: 40%;
          height: 47px; }
        .Form-Btn {
          width: 100%;
          height: 100%;
          @include mf(sx) {
            border-radius: 0 5px 5px 0; } } } } } }

