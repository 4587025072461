.Photogal {
  background-color: #242424;
  &-Body {
    position: relative; }
  &-Card {
    padding: 15px 9px 85px;
    background-color: #f0f0f0;
    border-radius: 3px;
    position: relative;
    z-index: 3;
    @include mf(sl) {
      padding: 25px 35px 85px; }
    @include mf(lg) {
      padding: 25px 35px 50px; } }
  &-Heading {
    margin-bottom: 10px;
    @include mf(sm) {
      margin-bottom: 30px; } }
  &-Subheading {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.1px;
    @include mf(sm) {
      margin-bottom: 30px; } }
  &-Content {}
  &-Slider {
    width: 100%; } }

.PhotogalSlider {
  @include mf(lg) {
    display: flex;
    flex-wrap: wrap; }
  &-Slide {
    width: 100%;
    margin: 0 5px;
    @include mf(lg) {
      width: calc(33.33% - 20px*2/3);
      margin-top: 20px;
      margin-left: 0;
      margin-right: 20px; }
    &:nth-child(1) {
      @include mf(lg) {
        margin-top: 0; } }
    &:nth-child(2) {
      @include mf(lg) {
        margin-top: 0; } }
    &:nth-child(3) {
      @include mf(lg) {
        margin-top: 0; } }
    &:nth-child(3n) {
      @include mf(lg) {
        margin-right: 0; } } }
  &-Link {
    width: 100%;
    display: block; }
  &-Img {
    width: 100%;
    border-radius: 3px;
    display: block; }
  .Slider-Arrow {
    top: calc(100% + 10px); } }
