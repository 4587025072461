.Contact {
  &-Body {}
  &-Content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mf(sl) {
      width: calc(50% - 20px);
      display: block;
      position: relative; } }
  &-Heading {
    width: 100%;
    margin-bottom: 25px;
    @include mf(sl) {
      margin-bottom: 40px; } }
  &-Info {
    width: 100%;
    margin-bottom: 30px;
    @include mf(sl) {
      margin-bottom: 50px; } }
  &-FormCard {}
  &-MapWrap {
    width: 100%;
    height: 300px;
    margin-bottom: 25px;
    @include mf(sx) {
      width: 50%;
      height: auto;
      margin: 0;
      order: 2; }
    @include mf(sl) {
      width: calc(100% + 20px);
      height: 100%;
      position: absolute;
      top: 0;
      left: calc(100% + 20px); } }
  &-Map {
    width: 100%;
    height: 100%;
    .ymaps-2-1-74-map {
      height: 100%; } } }

.ContactInfo {
  @include mf(xs) {
    display: flex; }
  &-Item {
    margin-bottom: 20px;
    @include mf(xs) {
      margin-right: 50px;
      margin-bottom: 0; }
    &:last-of-type {
      margin: 0; } }
  &-Icon {
    fill: $main-color;
    position: absolute;
    top: 0;
    left: 0; }
  &-Phone {
    padding-left: 35px;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    .ContactInfo-Icon {
      width: 18px;
      height: 18px;
      top: 0; } }
  &-Location {
    padding-left: 35px;
    font-size: 17px;
    position: relative;
    .ContactInfo-Icon {
      width: 20px;
      height: 31px;
      top: 2px; } } }

.ContactFormCard {
  width: 100%;
  padding: 30px 12px 40px;
  background-color: #292929;
  border-radius: 3px;
  @include mf(sx) {
    width: calc(50% - 20px);
    order: 1; }
  @include mf(sl) {
    width: 100%; }
  &-Title {
    max-width: 310px;
    margin: 0 auto 30px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    @include mf(sl) {
      font-size: 18px; } }
  &-Form {
    max-width: 310px;
    margin: 0 auto; }
  &-Simg {
    display: none; } }
