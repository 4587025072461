.Partners {
  background-color: #242424;
  &-Body {
    position: relative;
    z-index: 2; }
  &-Heading {
    margin-bottom: 15px;
    @include mf(sm) {
      margin-bottom: 50px; }
    @include mf(lg) {
      margin-bottom: 70px; } }
  &-Card {
    padding: 15px 13px 20px;
    background-color: #ffffff;
    border-radius: 3px;
    @include mf(sm) {
      padding: 40px 30px 50px; } }
  &-Content {
    display: flex;
    flex-wrap: wrap; }
  &-Item {
    width: 50%;
    height: 85px;
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mf(sx) {
      width: 33.33%; }
    @include mf(sl) {
      height: 155px; }
    @include mf(lg) {
      width: 25%; }
    &:nth-child(1),
    &:nth-child(2) {
      border-top: none; }
    &:nth-child(3) {
      @include mf(sx) {
        border-top: none; } }
    &:nth-child(4) {
      @include mf(lg) {
        border-top: none; } }
    &:nth-child(2n) {
      border-right: none;
      @include mf(sx) {
        border-right: 1px solid #e8e8e8; } }
    &:nth-child(3n) {
      @include mf(sx) {
        border-right: none; }
      @include mf(lg) {
        border-right: 1px solid #e8e8e8; } }
    &:nth-child(4n) {
      @include mf(lg) {
        border-right: none; } } }
  &-Img {
    max-width: 65%;
    max-height: 65%;
    display: block; }
  &-Simg {
    display: none;
    position: absolute;
    @include mf(lg) {
      display: block;
      top: -280px;
      right: -266px;
      z-index: -1; } } }
