.Calculation {
  background-color: #242424;
  &-Body {
    padding-bottom: 110px;
    @include mf(sm) {
      padding-bottom: 70px; } }
  &-Heading {
    font-size: 30px;
    font-weight: 900;
    color: $main-color;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
    @include mf(sm) {
      font-size: 40px; } }
  &-Subheading {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 900;
    color: #ffffff;
    position: relative;
    z-index: 3;
    @include mf(sm) {
      margin-bottom: 40px;
      font-size: 36px; } }
  &-Content {
    position: relative; }
  &-Card {
    max-width: 340px;
    @include mf(sm) {
      max-width: none; } } }

.CalculationCard {
  padding: 25px 10px 140px;
  background-color: #292929;
  border-radius: 3px;
  box-shadow: 0px 31px 59.5px 10.5px rgba(239, 221, 100, 0.11);
  position: relative;
  z-index: 2;
  @include mf(sm) {
    padding: 45px 60px 55px; }
  &-Content {
    max-width: 320px;
    position: relative;
    z-index: 2; }
  &-Form {
    .Form-Row {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0; } } }
  &-Simg {
    position: absolute;
    &_cloud-left {
      top: -40px;
      left: -65px;
      @include mf(sm) {
        left: -90px; } } } }
